<template>
  <aside
    class="menu is-unselectable"
    :class="{ 'is-invisible': !sidebar }"
  >
    <template v-for="(menu, index) in myMenus">
      <ul class="menu-list" :key="`options.${index}`">
        <template v-for="(option, optionIndex) in menu.options">
          <li
            :id="option.to"
            :key="`title.options.${index}.${optionIndex}`"
            @click="handler(option.to)"
          >

            <template v-if="option.children">
              <router-link
                :class="{ 'is-active': activeMenus.includes(option.label) }"
                :to="option.to"
              >
                <div>
                  <i v-if="option.icon" class="material-icons">{{ option.icon }}</i>
                  <img class="image__item" v-if="option.image" :src="require('@/assets/icon/' + option.image)" alt="option.label">
                  {{ option.label }}
                </div>
                <i class="material-icons" @click="toggleActiveMenu(option.label)">
                  {{ activeMenus.includes(option.label) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                </i>
              </router-link>
              <ul v-if="activeMenus.includes(option.label)">
                <li v-for="(child, subIndex) in option.children" :key="`options.${index}.${subIndex}`">
                  <router-link :to="child.to">
                    <i v-if="child.icon" class="material-icons">{{ child.icon }}</i>
                    <img class="image__item" v-if="child.image" :src="require('@/assets/icon/' + child.image)" alt="option.label">
                    <div v-else class="simulate_icon" />
                    {{ child.label }}
                  </router-link>
                </li>
              </ul>
            </template>
            <router-link v-else :to="option.to">
              <i v-if="option.icon" class="material-icons">{{ option.icon }}</i>
              <img class="image__item" v-if="option.image" :src="require('@/assets/icon/' + option.image)" alt="option.label">
              {{ option.label }}
            </router-link>
          </li>
        </template>
      </ul>
    </template>
  </aside>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import partnerMenu from '../../lib/partnerMenu'
import customerMenu from '../../lib/customerMenu'

export default {
  name: 'TheSidebar',
  data () {
    return {
      typeCustomer: 1,
      myMenus: []
    }
  },
  computed: {
    ...mapState({
      userType: state => state.authentication.userType,
      sidebar: state => state.ui.sidebar,
      activeMenus: state => state.ui.activeMenus,
      activeoption: state => state.ui.activeoption
    })
  },
  methods: {
    ...mapMutations(['toggleActiveMenu', 'cleanActiveMenus', 'getTheActiveMenu']),
    handler (optionId) {

    },
    optionActive () {
      this.getTheActiveMenu(this.$route.path)
      const activeElement = document.getElementsByClassName('activeOption')
      if (activeElement.length > 0) activeElement[0].classList.remove('activeOption')
      let element, name, arr
      if (this.activeoption) {
        element = document.getElementById(this.activeoption)
        if (element) {
          name = 'activeOption'
          arr = element.className.split(' ')
          if (arr.indexOf(name) === -1) {
            element.className += ' ' + name
          }
        }
      }
    }
  },
  beforeMount () {
    this.myMenus = this.userType === this.typeCustomer ? customerMenu : partnerMenu
  },
  mounted () {
    this.optionActive()
  },
  watch: {
    '$route.path' (newValue, oldValue) {
      this.optionActive()
    }
  }
}
</script>

<style scoped>
  aside {
    background: #FFFFFF;
    height: auto;
    min-width: 200px;
    border: solid 1px #e1e5eb;
  }

  aside p.menu-label {
    margin: 0;
    padding: 1em;
    color: #4E4E4E;
  }

  aside.is-invisible {
    display: none;
  }

  .menu-list a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #b5b5c3;
    flex-direction: row;
  }
  .menu-list a > div {
    display: flex;
    align-items: center;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    width: 100%;
  }

  .menu-list li {
    min-height: 50px;
  }

  .image__item {
    margin: 10px;
    max-width: 35px;
  }

  .menu-list a:hover {
    background: #007fbf21;
    color: #5d6990;
  }

  .router-link-active {
    background: #007fbf21;
    color: #5d6990 !important;
  }

  .menu-list a.is-active:hover {
    background: #007fbf21;
    color: #5d6990;
  }

  .menu-list a i:first-child {
    padding-right: .5rem;
  }

  .menu-list a i:last-child {
    padding-left: .5rem;
  }

  .menu-list li ul {
    border-left: none;
    margin: 0;
    width: 100%;
    padding-left: 0;
  }

  .menu-list a.is-active {
    background-color: transparent;
  }

  .simulate_icon {
    width: 55px;
  }

  @media screen and (max-width: 1023px) {
    aside {
      width: 100%;
      padding-bottom: 1em;
    }
  }

  @media screen and (min-width: 1024px) {
    aside {
      height: 100%;
      width: 150px;
      overflow-y: auto;
    }
  }
</style>
