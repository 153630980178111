export default [
  {
    title: 'Administrador',
    index: 1,
    options: [
      {
        id: 1,
        label: 'Dashboard',
        to: '/dashboard',
        image: 'dashboard.png'
      },
      {
        id: 2,
        label: 'Clientes',
        to: '/clientes',
        image: 'clientes.png'
      }
    ]
  }
]
